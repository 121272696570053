<template>
  <div>

    <p class="text-center style-text-p">Cadastro de Ouvidoria</p>
    <vuestic-widget>
      <p class="style-box">Ouvidoria</p>
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <div class="input-group">
                <input
                  v-model="ouvidoria.funcionario"
                  id="simple-input-name"
                >
                <label
                  for="simple-input-name"
                  class="control-label"
                >Nome do Funcionario</label>
                <span
                  class="alert-danger"
                  v-if="erros.funcionario"
                >{{erros['funcionario'][0]}}</span>
              </div>
            </div>
          </div>
          <div class="col">
            <label class="select-control-label">Setor</label>
            <multiselect
              v-model="areaSelecionada"
              placeholder="Selecione"
              track-by="id"
              label="nome"
              :options="areas"
              selectedLabel="Selecionado"
              select-label="Selecione um setor"
              deselect-label="Remover setor"
              :loading="loading"
            >
              <span slot="noResult">Setor não encontrado.</span>
              <template slot="noOptions">Sem setores</template>
            </multiselect>
            <span
              class="alert-danger mb-2"
              v-if="erros.setor"
            >{{erros['setor'][0]}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <div class="input-group">
                <input
                  v-model="ouvidoria.titulo"
                  id="simple-input-title"
                >
                <label
                  for="simple-input-title"
                  class="control-label"
                >Titulo</label>
                <span
                  class="alert-danger"
                  v-if="erros.titulo"
                >{{erros['titulo'][0]}}</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <div class="input-group">
                <input
                  type="date"
                  v-model="ouvidoria.prazo"
                  id="simple-input-prazo"
                  required
                >
                <label
                  class="select-control-label"
                  for="simple-input-prazo"
                >Prazo</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <div class="input-group">
                <textarea
                  v-model="ouvidoria.queixa"
                  id="simple-input-call"
                />
                <label
                  for="simple-input-call"
                  class="control-label"
                >Descrição</label>
                <span
                  class="alert-danger"
                  v-if="erros.queixa"
                >{{erros['queixa'][0]}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex justify-content-end">
            <button
                class="btn btn-pale btn-micro mr-2"
                @click="limparCampos()"
              >Limpar Campos
              </button>
              <button
                class="btn btn-primary btn-micro"
                @click="salvar"
              >Confirmar
              </button>
          </div>
        </div>
      </div>
  </vuestic-widget>
	</div>
</template>

<script>
export default {
  props: {
    idEmpresa: { requered: true },
  },
  data() {
    return {
      loading: false,
      areas: [],
      empresas: [],
      areaSelecionada: {},
      ouvidoria: {
        situacao: 1,
        queixa: '',
        prazo: null,
        setor: null,
        setor_do_relatado: null,
        empresa: this.idEmpresa,
        funcionario: '',
        titulo: '',
      },
      erros: {},
    };
  },
  created() {
    this.getEmpresa();
  },
  methods: {
    getAreas(listIdAreas) {
      this.$axios.get('/api/area-list/').then((res) => {
        this.loading = false;
        this.areas = (res.data)
          .filter(area => listIdAreas.findIndex(el => el === area.id) !== -1);
      }).catch(() => {
        this.loading = false;
      });
    },
    getEmpresa() {
      this.$axios.get(`/api/empresa-list/${this.idEmpresa}/`).then((res) => {
        this.getAreas(res.data.areas);
      }).catch(() => {
        this.loading = false;
      });
    },
    salvar() {
      this.$axios.post('api/ouvidoria/', this.ouvidoria).then(() => {
        this.message('success', 'Ouvidoria enviada');
        this.limparCampos();
      }).catch((err) => {
        this.message('error', 'Erro');
        this.erros = err.response.data;
      });
    },
    limparCampos() {
      this.areaSelecionada = {};
      this.empresaSelecionada = {};
      this.ouvidoria = {
        empresa: this.idEmpresa,
        situacao: 1,
        queixa: '',
        funcionario: '',
        titulo: '',
        prazo: null,
        setor: null,
      };
      this.erros = {};
    },
    message(type, title) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showCancelButton: false,
        type,
        title,
        showConfirmButton: false,
        timer: 2000,
      });
    },
  },
  watch: {
    areaSelecionada(val) {
      this.ouvidoria.setor = val ? val.id : null;
    },
  },
};
</script>

<style scoped>
.select-control-label {
	left: 22px;
}
</style>
