<template>
  <div class="app">
    <ouvidoria
      class="mt-5"
      :idEmpresa="id"
    />
  </div>
</template>

<script>
import ouvidoria from '@/components/user/form/ouvidoria.vue';

export default {
  components: { ouvidoria },
  data() {
    return {
      nomeDaEmpresa: null,
      id: null,
    };
  },
  created() {
    this.nomeDaEmpresa = this.$router.history.current.params.empresa;
    this.escolherEmpresa(this.nomeDaEmpresa);
  },
  methods: {
    escolherEmpresa(nome) {
      switch (nome) {
      case 'IndustriasDureino':
        this.id = 3;
        break;
      case 'EldoradoBebidas':
        this.id = 9;
        break;
      case 'GestorConsultoria':
        this.id = 2;
        break;
      case 'Mambee':
        this.id = 4;
        break;
      case 'ViaBebidas':
        this.id = 11;
        break;
      case 'AlfaBebidas':
        this.id = 12;
        break;
      default:
        this.modalErro();
        break;
      }
    },
    modalErro() {
      this.$swal({
        type: 'error',
        title: 'Oops...',
        text: 'Link Incorreto',
        backdrop: 'rgba(0,0,0,1)',

        allowOutsideClick: false,
        preConfirm: () => {
          this.$router.push('/');
        },
      });
    },
  },
};
</script>

<style scoped>
.app {
	width: 90%;
	margin: 0 auto;
}
</style>
