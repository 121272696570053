<template>
  <div>
    <ouvidoria :idEmpresa="idEmpresa" />
  </div>
</template>

<script>
import ouvidoria from '@/components/my-components/form/ouvidoria.vue';

export default {
  components: { ouvidoria },
  data() {
    return {
      idEmpresa: localStorage.empresaSelecionada,
    };
  },
};
</script>

<style>
</style>
